import { TaskType } from '@innedit/innedit-type';
import { navigate, PageProps } from 'gatsby';
import { TaskData } from 'packages/innedit';
import React, { FC, useEffect } from 'react';

import HOCGroup from '~/components/Group/HOC';
import Task from '~/components/List/Item/Task';
import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import ListBody from '~/containers/Espace/List/Body';
import task from '~/params/task.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageEspaceTaskUpdate: FC<PageProps & EspaceProps & UserProps> = props => {
  const { espace, location, user, params } = props;

  const { espaceId, docId } = params;
  const model = new TaskData({ espaceId, params: task });

  useEffect(() => {
    model.incrementView(docId);
  }, [docId]);

  return (
    <TemplateEspace
      collectionName="tasks"
      docId={docId}
      espace={espace}
      user={user}
    >
      <CMSView>
        <Form
          displayArchive
          displayDescription
          displayFeatured
          docId={docId}
          itemPathnamePrefix={`/espaces/${espaceId}/tasks/`}
          model={model}
          name="task"
          type="update"
        >
          <div className="grid md:grid-cols-3">
            <div className="md:col-span-2">
              <HOCGroup
                addOnClick={() =>
                  navigate(`/espaces/${espaceId}/tasks/create?parent=${docId}`)
                }
                bodyProps={{
                  className: 'p-0',
                }}
                title="Sous-tâches"
              >
                <ListBody<TaskType, TaskData>
                  allowSorting
                  itemList={Task}
                  itemPathnamePrefix={`/espaces/${espaceId}/tasks/`}
                  model={
                    new TaskData({
                      espaceId,
                      orderDirection: 'desc',
                      orderField: 'datetime',
                      params: task,
                      parentId: docId,
                      wheres: {
                        parent: docId,
                      },
                    })
                  }
                  pathname={location.pathname}
                  search={location.search}
                  title="Sous-tâches"
                  user={user}
                />
              </HOCGroup>
            </div>
          </div>
        </Form>
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceTaskUpdate);
